/* Custom styles for small screens (max-width: 768px) */
@media (max-width: 1023px) {
  .toast-message {
    font-size: 32px;
    padding: 30px 20px;
  }

  /** Used to position the icon **/
  .Toastify__toast-icon {
    height: 40px;
    width: 40px;
    padding-right: 10px;
  }

  .loading-spinner {
    width: 100px;
    /* Adjust the width to your desired size */
    height: 100px;
    /* Adjust the height to your desired size */
    /* Other styling properties for your spinner */
  }

  /* Date Picker */
  /* .react-datepicker__month-container {
    width: 550px !important;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    width: calc(450px / 7);
    padding: 15px;
  }

  .react-datepicker__day {
    padding: 15px;
  }

  .react-datepicker__navigation {
    width: 80px;
    height: 130px;
    outline: none;
    border: none;
    top: 10px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #000;
    scale: 200%;
  }

  .react-datepicker__navigation--next {
    border-left-color: #000;
    scale: 200%;
  } */
  

}

.Toastify__toast-container--top-right {
  width: auto;
}

