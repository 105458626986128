.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    z-index: 1001;
    max-width: 400px; /* Adjust this value as needed */
  }
  
  .show-link {
    /* Add styles for the link popup here */
    /* Make sure it's absolutely positioned and doesn't affect the layout */
  }